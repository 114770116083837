import React, { useState, useEffect, useContext } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { Card, Grid, IconButton, Typography, ImageList } from "@mui/material";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModalImage from "react-modal-image";
import { makeStyles } from "@material-ui/core";
import DialogueBox from "../../common/DialogueBox";
import click_here from "./click_here_SVG.svg";
import RejectionModal from "../Stepper/RejectionModal";
import { getFileExtension } from "../../../utils/getExtension";
import { Button } from "antd";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    modal: {
        backgroundColor: "blue",
        fontSize: "12px",
    },
}));

const Kyc = ({ onClick }) => {
    const { applicDetails } = useSelector((state) => state.loanApplication);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [currentItem, setCurrentItem] = useState();
    const status = 2;
    const URL = process.env.REACT_APP_PARTNER_URL;
    const classes = useStyles();

    //Rejection Modal
    const [openRejModal, setOpenRejModal] = useState(false)

    const rejModalOpen = (item) => {
        // setDialogText("reject");
        setCurrentItem(item);
        setOpenRejModal(true)
    }

    const rejModalClose = () => {
        setOpenRejModal(false)
    }
    const onBtnClick = () => {
        setOpenDialogue(!openDialogue);
    };

    return (
        <>
        <TabPanel value="2">
            <Card style={{ height: "250px", overflowY: "scroll" }}>
            {applicDetails &&
                applicDetails.documets &&
                applicDetails.documets[2]?.map((item) => {
                    const extension = getFileExtension(item.fileName)
                return (
                    <Grid container justify="center" alignItems="center" >
                        <Grid item xs={4} alignItem="center" display="flex" justifyContent={'center'} marginBottom={2}>
                            {
                                extension === 'pdf' ?
                                <a href={item.fileName} target="_blank" rel="noopener noreferrer">
                                    <Button type="primary">View File</Button>
                                </a>
                                :
                                <ImageList sx={{ mt: "13px", ml: "31px" }}>
                                    <ModalImage
                                        hideZoom="true"
                                        hideDownload="true"
                                        smallSrcSet={click_here}
                                        large={item.fileName}
                                        // alt="Click Here"
                                    />
                                </ImageList>
                            }
                        </Grid>
                        <Grid item xs={3} alignItem="center"marginBottom={2}>
                            <Typography>
                            <strong>
                                {item.subfileType === 1
                                ? "Aadhar Card"
                                : item.subfileType === 2
                                    ? "PAN CARD"
                                    : ""}
                            </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={3} alignItem="center"marginBottom={2}>
                            <Typography>
                            <strong>{(item.status === 'verified' || item.status === 'partially-verified') ? 'verified' : item.status}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} alignItem="center"marginBottom={2}>
                            {
                                item.status === 'uploaded' &&
                                <>
                                    <IconButton onClick={() => {
                                        setOpenDialogue(true);
                                        setDialogText("accept");
                                        setCurrentItem(item);
                                    }} style={{paddingTop:0,paddingBottom:0}}>
                                        <DoneIcon
                                        color={
                                            item.status === "uploaded" ? "primary" : "disabled"
                                        }
                                        // onClick={() => onClick(2, item._id, "verified")}

                                        />
                                    </IconButton>
                                    <IconButton onClick={
                                        item.status != "rejected"
                                        ? () => rejModalOpen(item)// () => onClick(2, item._id, "rejected")
                                        // () => {
                                        //   setOpenDialogue(true);
                                        //   setDialogText("reject");
                                        //   setCurrentItem(item);
                                        // }
                                        : () => { }
                                    }style={{paddingTop:0,paddingBottom:0}}>
                                        <CloseRoundedIcon
                                        color={
                                            item.status === "uploaded" ? "error" : "disabled"
                                        }
                                        />
                                    </IconButton>
                                </>
                            }
                        </Grid>
                    </Grid>
                );
                })}
            </Card>
        </TabPanel>
        {openDialogue ? (
            <DialogueBox
            open={openDialogue}
            onClose={onBtnClick}
            text={dialogText}
            handlefnc={onClick}
            item={currentItem}
            status={status}
            />
        ) : null}

        {
            openRejModal ? <RejectionModal open={openRejModal} handleClose={rejModalClose} handlefnc={onClick} item={currentItem} status={status} /> : ''
        }
        </>
    );
};

export default Kyc;
