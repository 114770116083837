import React, { useState,} from "react";
import TabPanel from "@mui/lab/TabPanel";
import { Card, Grid, IconButton, Typography, ImageList } from "@mui/material";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModalImage from "react-modal-image";
import DialogueBox from "../../common/DialogueBox";
import click_here from "./click_here_SVG.svg";
import RejectionModal from "../Stepper/RejectionModal";
import { getFileExtension } from "../../../utils/getExtension";
import { Button } from "antd";
const Ops = ({ onClick }) => {
    const { applicDetails } = useSelector((state) => state.loanApplication);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [currentItem, setCurrentItem] = useState();
    const status = 1;
    const URL = process.env.REACT_APP_PARTNER_URL;

    //Rejection Modal
    const [openRejModal, setOpenRejModal] = useState(false)

    const rejModalOpen = (item) => {
        // setDialogText("reject");
        setCurrentItem(item);
        setOpenRejModal(true)
    }

    const rejModalClose = () => {
        setOpenRejModal(false)
    }
    
    const onBtnClick = () => {
        setOpenDialogue(!openDialogue);
    };

    return (
        <>
        <TabPanel value="1">
            <Card style={{ height: "250px", overflowY: "scroll" }}>

            {applicDetails &&
                applicDetails.documets &&
                applicDetails.documets[1]?.map((item) => {
                    const extension = getFileExtension(item.fileName)
                    return (
                        <Grid container justify="center" alignItems="center">
                        <Grid item xs={4} alignItem="center" display="flex" justifyContent={'center'}>
                            {
                                extension === 'pdf' ?
                                <a href={item.fileName} target="_blank" rel="noopener noreferrer">
                                    <Button type="primary">View File</Button>
                                </a>
                                :
                                <ImageList sx={{ mt: "13px", ml: "31px" }}>
                                    <ModalImage
                                        hideZoom="true"
                                        hideDownload="true"
                                        smallSrcSet={click_here}
                                        large={item.fileName}
                                        // alt="Click Here"
                                    />
                                </ImageList>
                            }
                        </Grid>
                        <Grid item xs={4} alignItem="center">
                            <Typography>
                            <strong>{item.status}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} alignItem="center">
                            {item.status === "verified" ||
                            item.status === "rejected" ? (
                            ""
                            ) : (
                            <IconButton onClick={() => {
                                setOpenDialogue(true);
                                setDialogText("accept");
                                setCurrentItem(item);
                            }}>
                                <DoneIcon
                                color={
                                    item.status === "uploaded" ? "primary" : "disabled"
                                }
                                />
                            </IconButton>
                            )}

                            {item.status === "verified" ||
                            item.status === "rejected" ? (
                            ""
                            ) : (
                            <IconButton onClick={
                                item.status != "rejected"
                                ? () => rejModalOpen(item)// () => onClick(2, item._id, "rejected")
                                // () => {
                                //   setOpenDialogue(true);
                                //   setDialogText("reject");
                                //   setCurrentItem(item);
                                // }
                                : () => { }
                            }>
                                <CloseRoundedIcon
                                color={
                                    item.status === "uploaded" ? "error" : "disabled"
                                }
                                // onClick={() => onClick(1, item._id, "rejected") }
                                />
                            </IconButton>
                            )}
                            
                        </Grid>
                        </Grid>
                    );
                })}
            </Card>
        </TabPanel>
        {openDialogue ? (
            <DialogueBox
            open={openDialogue}
            onClose={onBtnClick}
            text={dialogText}
            handlefnc={onClick}
            item={currentItem}
            status={status}
            />
        ) : null}
        
        {
            openRejModal ? <RejectionModal open={openRejModal} handleClose={rejModalClose} handlefnc={onClick} item={currentItem} status={status} /> : ''
        }
        </>
    );
};

export default Ops;
