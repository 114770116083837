import React from 'react'
import tranchesStyle from './TranchesStyles'
import { Grid } from '@material-ui/core'
import moment from 'moment'

const TrancheViewModal = ({openViewData}) => {
    const classes = tranchesStyle()
    return (
        <div className={classes.viewMainContentWrap}>
            <div className={classes.mainTitle}>Tranche Details</div>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>DT/RT Code:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.userid}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Tranche ID:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.trancheid}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Name:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.name}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Tranche Amount:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.tranche_amount}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Bank Ref Num:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.bank_reference_number}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Loan Amount:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.loan_amount}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Payback Date:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{moment(openViewData?.payback_date).format('DD MMMM YYYY')}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Created Date:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{moment(openViewData?.created_on).format('DD MMMM YYYY')}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Status:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.status_text}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewLabel}>Failed Reason:</span>
                </Grid>
                <Grid item xs={6}>
                    <span className={classes.viewValue}>{openViewData?.failed_reason !== null ? openViewData?.failed_reason : 'NA'}</span>
                </Grid>
            </Grid>
        </div>
    )
}

export default TrancheViewModal