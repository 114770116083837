import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableCell, Button, IconButton, Tooltip } from "@material-ui/core";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import { DatePicker } from 'antd';
import { TXT_HOME, TRANCHES } from "../../utils/header";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import moment from "moment";
import useExcel from '../common/useExcel'
import DownloadIcon from '@mui/icons-material/Download';
import { getTranchesList, getTranchesListExcel } from "../../slice/tranchesListSlice";
import { BsFillEyeFill } from "react-icons/bs";
import DoneIcon from "@mui/icons-material/Done";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModalWrap from './ModalWrap';

const TranchesList = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const sharedClasses = useSharedClass();
    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const [openDialogue, setOpenDialogue] = useState('');
    const [openViewData, setOpenViewData] = useState('');

    const { tranchesList, totalCount, isLoading } = useSelector((store) => store.trancheslistapi);
    const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(tranchesList, HeadCells.tranchesListHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }

    useEffect(() => {
        const dates = {
            pageNo,
            fromDate,
            toDate,
        }
        if(openDialogue === ''){
            dispatch(getTranchesList(dates))
            setShowPageNtn(true);
        }
    }, [fromDate, toDate, pageNo, dispatch,openDialogue])

    const excelData = useExcel()

    let serialNo = PAGE_SIZE * slNo;
    const downloadExcelsheet = ()=>{
        const dates = {
            pageNo,
            fromDate,
            toDate,
            totalCount
        }
        dispatch(getTranchesListExcel(dates))
        .then(res=>{
          if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "RT/DT Code" : item.userid,
                    "Tranche Id" : item.trancheid,
                    "Name" : item.name,
                    "Tranche Amount" : item.tranche_amount,
                    "Bank Ref Num" : item.bank_reference_number,
                    "Loan Amount" : item.loan_amount,
                    "Payback Date" : moment(item.payback_date).format('DD MMMM YYYY'),
                    "Created Date" : moment(item.created_on).format('DD MMMM YYYY'),
                    "Status" : item.status_text,
                    "Failed Reason" : item.failed_reason,
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'TrancheSheet', 'TranchesList.xlsx')
          }
        })
        .catch(err=>{
          console.error(err);
        })
    }
    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TRANCHES}
                        subheader={`${TXT_HOME}/${TRANCHES}`}
                    />

                    <article className={sharedClasses.searchAddParent}>
                        <div className={sharedClasses.lpsearchBar} />

                        <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                            <RangePicker
                                placeholder={["From Date", "To Date"]}
                                defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                                format={dateFormat}
                                className={sharedClasses.datePicker}
                                onChange={onDateChange}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                className={sharedClasses.download}
                                onClick={downloadExcelsheet}
                                disabled = {totalCount === 0 ? true : false}
                                endIcon={<DownloadIcon />}
                                style={{
                                    color: '#ffffff',
                                    width: '250px',
                                    height: '50px',
                                    fontSize: '15px',
                                    borderRadius: '5px',
                                    backgroundColor: '#5A5DF6'
                                }}
                            >
                                Download Ledger
                            </Button>
                        </div>
                    </article>

                    <section className={sharedClasses.mainSection}>
                        <TblContainer>
                            <TblHead />
                            {
                                isLoading ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={9} align='center'>
                                                <ProgressSpinner />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {
                                            (tranchesList.length !== 0) ?
                                                (
                                                    tranchesList &&
                                                    tranchesList.map((item, key) => {
                                                        key++;
                                                        serialNo++;
                                                        return (
                                                            <TableRow key={key.toString()}>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {serialNo}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.userid}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.trancheid}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.tranche_amount}
                                                                </TableCell>
                                                                
                                                                
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {moment(item.created_on).format('DD MMMM YY')}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.status_text}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {
                                                                        item.status === 2 ?
                                                                        <>
                                                                            <Tooltip title= "Mark it as disbursed">
                                                                                <IconButton 
                                                                                onClick={ () => {
                                                                                    setOpenViewData(item)
                                                                                    setOpenDialogue('accept');
                                                                                }}
                                                                                >
                                                                                    <DoneIcon color={ "primary"}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title= "Mark it as failed">
                                                                                <IconButton
                                                                                    onClick={ () => {
                                                                                        setOpenViewData(item)
                                                                                        setOpenDialogue('reject');
                                                                                    }}
                                                                                >
                                                                                    <CloseRoundedIcon color={"error"}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                        :
                                                                        '--'
                                                                    }
                                                                </TableCell>
                                                                
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    <Tooltip title="View Tranche Details">
                                                                        <IconButton 
                                                                            onClick={ () => {
                                                                                    setOpenViewData(item)
                                                                                    setOpenDialogue('view');
                                                                                }}>
                                                                            <BsFillEyeFill className={sharedClasses.actionIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={9} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                                                    </TableRow>
                                                )
                                        }
                                    </TableBody>
                                )
                            }
                        </TblContainer>
                        {showPageNtn ? <TblPagination /> : ""}
                    </section>
                    {
                        openDialogue &&
                        <ModalWrap open={openDialogue} openViewData={openViewData} setOpenDialogue={setOpenDialogue}/>
                    }
                </div>
            </div>
        </>
    )
}

export default TranchesList