import React from 'react'
import { Box, IconButton, Modal } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import ActionModal from './ActionModal';
import TrancheViewModal from './TrancheViewModal';
import tranchesStyle from './TranchesStyles';

const ModalWrap = ({ open,openViewData, setOpenDialogue }) => {
    const classes = tranchesStyle()

    return (
        <Modal open={open !=='' ? true : false} style={{zIndex:'1350px'}}>
            <Box className={classes.modalwrap}>

                <Box className={classes.iconwrap}>
                    <IconButton onClick={()=> setOpenDialogue('')}>
                        <AiOutlineClose />
                    </IconButton>
                </Box>
                {
                    open === 'view' ?
                    <TrancheViewModal open={open} openViewData={openViewData} setOpenDialogue={setOpenDialogue}/>
                    :
                    <ActionModal open={open} openViewData={openViewData} setOpenDialogue={setOpenDialogue}/>
                }
            </Box>
        </Modal>
    )
}

export default ModalWrap
