const  getFileExtension = (url) => {
    const pathArray = url.split('/');
    const lastSegment = pathArray[pathArray.length - 1];
    const filenameArray = lastSegment.split('.');
    
    if (filenameArray.length > 1) {
      return filenameArray[filenameArray.length - 1];
    } else {
      return null; // No file extension found
    }
}

export {
    getFileExtension
}