import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputLabel, Stack, TextField } from '@mui/material';
import useSharedClasses from "../../Sharedfeatures/SharedClasses";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../common/TextArea';
import { getRejectedDropdownList } from '../../../slice/loanapplication';
import { AiFillSecurityScan } from 'react-icons/ai';
import './RejectionModal.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px',
    boxShadow: 24,
    p: 4,
};

const RejectionModal = ({ value, open, handleClose, handlefnc, item, status }) => {

    const sharedClasses = useSharedClasses();
    const [reasonError, setReasonError] = useState("");
    const [remarkError, setRemarkError] = useState("");
    const [remarksText, setRemarksText] = useState('')
    const [type, setType] = useState(0)
    const { rejReasons } = useSelector((state) => state.loanApplication);

    const handleChange = (e) => {
        setType(e.target.value);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRejectedDropdownList());
    }, [])

    function clearError() {
        setReasonError("");
        setRemarkError("");
    }

    const handleReject = (e) => {
        e.preventDefault();
        if (!type) {
            setReasonError("This Field is Required*");
            setTimeout(clearError, 2000);
        } else if (!remarksText) {
            setRemarkError("This Field is Required*");
            setTimeout(clearError, 2000);
        }
        else {
            handlefnc(status, item._id, "rejected", type, remarksText)
            handleClose()
        }
    }

    return (
        <>
            <Box sx={style}>
                <form>
                    <Typography variant="h5" gutterBottom align='center' style={{ marginBottom: '20px' }}>
                        Rejected Document
                    </Typography>

                    <div className="selectdiv">
                        {/* <InputLabel>Select Type</InputLabel> */}
                        <select
                            // name="Type"
                            required
                            value={type}
                            label="Type"
                            onChange={handleChange}
                            className={sharedClasses.lpselectDropdownBar}
                        >
                            <option value={0} disabled>Reason</option>
                            {
                                rejReasons && rejReasons.map(item => (
                                    <option key={item._id} value={item.title}>{item.title}</option>
                                ))
                            }
                        </select>
                        {reasonError && <p style={{ color: 'red', fontSize: '10px', paddingLeft: '15px' }}>{reasonError}</p>}
                    </div>

                    <TextField
                        style={{ marginTop: '20px' }}
                        label="Remarks"
                        name="remark"
                        value={remarksText}
                        onChange={(e) => setRemarksText(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        error={remarkError ? true : false}
                        helperText={remarkError}
                    />

                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        style={{ marginTop: '30px' }}
                    >
                        <Button variant="contained" onClick={handleReject}>Submit</Button>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Stack>
                </form>
            </Box>
        </>
    )
}

export default RejectionModal
