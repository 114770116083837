import React, { useState } from "react";
import {
  Select as MuiSelect,
  MenuItem,
  Button,
  Box,
} from "@material-ui/core";
import {
  CURRENCY,
  DECI_PLACES,
  CURRENCY_MULTIPLES_OF
} from "../../utils/info"
import Input from "../controls/Input";
import validator from "validator";
import Icon from "../common/Icon";
import { star } from "../../utils/index";
import LoanSettingsOptions from "./Options/LoanSettingsOptions";
import makeStyles from './styles.jsx'

function LoanCurrencyDetails({
  value,
  handleInputChange,
  handleNext,
  handleBack,
}) {
  const classes = makeStyles();
  const [currencyError, setCurrencyError] = useState("");
  const [dadError, setDadError] = useState("");
  const [imoError, setImoError] = useState("");
  const [multiError, setmultiError] = useState("");
  function clearError() {
    setCurrencyError("");
    setDadError("");
    setImoError("");
  }
  const submitFormData = (e) => {
    e.preventDefault();
    if (validator.isEmpty(value.digitsAfterDecimal)) {
      setDadError("This field is required*");
      setTimeout(clearError, 2000);
    } else if (validator.isEmpty(value.inMultiplesOf)) {
      setImoError("This field is required*");
      setTimeout(clearError, 2000);
    } else if (!value.installmentAmountInMultiplesOf) {
      setmultiError("This field is required*");
      setTimeout(clearError, 2000);
    }
    else if (value.installmentAmountInMultiplesOf <= 0) {
      setmultiError("Must be greater than Zero");
      setTimeout(clearError, 2000);
    }
    else {
      handleNext();
    }
  };

  const NUMERIC_DASH_REGEX = /^[e+-.]+$/;

  return (
    <>
      <div className={classes.container}>
        <h3>
          Currency{" "} <span style={{ color: `${star}` }}>*</span>
          <Icon title={DECI_PLACES} />
        </h3>
        <div>
          <Input
            type="text"
            name="currencyCode"
            onChange={handleInputChange}
            value={value.currencyCode || ""}
            label=""
            disabled={true}
          // error={irfError}
          />
        </div>
      </div>
      <div className={classes.container}>
        <h3>
          Decimal Places{" "} <span style={{ color: `${star}` }}>*</span>
          <Icon title={DECI_PLACES} />
        </h3>
        <div>
          <Input
            type="number"
            name="digitsAfterDecimal"
            onChange={handleInputChange}
            value={value.digitsAfterDecimal || ""}
            label=""
            error={dadError}
            onKeyDown={(event) => {
              if (NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.container}>
        <h3>
          Currency In Multiples Of{" "} <span style={{ color: `${star}` }}>*</span>
          <Icon title={CURRENCY_MULTIPLES_OF} />
        </h3>
        <div>
          <Input
            type="number"
            name="inMultiplesOf"
            onChange={handleInputChange}
            value={value.inMultiplesOf || ""}
            label=""
            error={imoError}
            onKeyDown={(event) => {
              if (NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.container}>
        <h3>
          Installment in Multiples Of <span style={{ color: `${star}` }}>*</span>
        </h3>
        <div>
          <Input
            type="number"
            name="installmentAmountInMultiplesOf"
            onChange={handleInputChange}
            value={value.installmentAmountInMultiplesOf || ""}
            label=""
            error={multiError}
            onKeyDown={(event) => {
              if (NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, width: 750 }}>
        <Button
          className={classes.nextBtn}
          variant="contained"
          color="primary"
          data-testid="nextBtn"
          onClick={handleBack}
        >
          BACK
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          className={classes.nextBtn}
          variant="contained"
          color="primary"
          data-testid="nextBtn"
          onClick={submitFormData}
        >
          NEXT
        </Button>
      </Box>
    </>
  );
}

export default LoanCurrencyDetails;
