import React, { useState, useEffect } from "react";
import { Select as MuiSelect, MenuItem, RadioGroup as MuiRadioGroup, FormControlLabel, Radio, Box } from "@material-ui/core";
import Button from "../controls/Button";
import Input from "../controls/Input";
import Icon from "../common/Icon";
import { star } from "../../utils/index";
import { useSelector, useDispatch } from "react-redux";
import LoanTermsOptions from "./Options/LoanTermsOptions";
import makeStyles from './styles.jsx'
import { MAX_PRINCIPAL, MIN_PRINCIPAL, PRINCIPAL, NO_OF_REPAYMENTS, FLOATING_INT, NOMINAL_INT, REPAID_EVERY, CHARGES } from "../../utils/info";
import { getChargesListDropdown, getChargesOverdue } from "../../slice/overduecharges";

function LoanTerms({ value, setValues, handleInputChange, handleNext, handleBack }) {
  const classes = makeStyles();
  const dispatch = useDispatch();
  const [minPError, setMinPError] = useState("");
  const [maxPError, setMaxPError] = useState("");
  const [pError, setPError] = useState("");
  const [chargesError, setChargesError] = useState("");
  const [norError, setNorError] = useState("");
  const [irpError, setIrpError] = useState("");
  const [irfError, setIrfError] = useState("");
  const [reError, setReError] = useState("");
  const [floatingError, setFloatingRatesError] = useState(false);
  const [IRDError, setIRDError] = useState(false);
  const [DDLRError, setDDLRError] = useState(false);
  const [MDLRError, setMDLRError] = useState(false);
  const [maxDLRError, setmaxDLRError] = useState(false);
  const { floatingInterest } = useSelector((state) => state.loan);
  const { chargesList } = useSelector(store => store.chargesOverdue)
  // console.log("CHARges", chargesList)

  useEffect(() => {
    dispatch(getChargesListDropdown())
  }, [])


  function clearError() {
    setMinPError("");
    setMaxPError("");
    setPError("");
    setChargesError("");
    setNorError("");
    setIrpError("");
    setIrfError("");
    setReError("");
    setFloatingRatesError("");
    setIRDError("");
    setDDLRError("");
    setMDLRError("");
    setmaxDLRError("");
  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (!value.principal) {
      setPError("This field is required*");
      setTimeout(clearError, 5000);
    } else if (!value.minPrincipal) {
      setMinPError("This field is required*");
      setTimeout(clearError, 5000);
    } else if (!value.maxPrincipal) {
      setMaxPError("This field is required*");
      setTimeout(clearError, 5000);
    } else if (!value.numberOfRepayments) {
      setNorError("This field is required*");
      setTimeout(clearError, 5000);
    } else if (!value.charges[0].id) {
      setChargesError("This field is required*");
      setTimeout(clearError, 5000);
    }
    // else if(value.minPrincipal < value.principal){
    //   setMinPError("Minimum Principal amount must not be greater than Principal");
    //   setTimeout(clearError, 8000);
    // }
    // else if(value.maxPrincipal < value.principal || value.maxPrincipal > value.principal){
    //   setMaxPError("Maximum Principal amount must not be greater or lesser than Principal");
    //   setTimeout(clearError, 8000);
    // }
    // else if (value.isLinkedToFloatingInterestRates === "true" && !value.interestRateDifferential) {
    //   setIRDError("Required*");
    //   setTimeout(clearError, 5000);
    // } else if (value.isLinkedToFloatingInterestRates === "true" && !value.defaultDifferentialLendingRate) {
    //     setDDLRError("Required*");
    //     setTimeout(clearError, 5000);
    // } else if (value.isLinkedToFloatingInterestRates === "true" && !value.minDifferentialLendingRate) {
    //   setMDLRError("Required*");
    //   setTimeout(clearError, 5000);
    // } else if (value.isLinkedToFloatingInterestRates === "true" && !value.maxDifferentialLendingRate) {
    //   setmaxDLRError("Required*");
    //   setTimeout(clearError, 5000);
    // }
    // else if(value.defaultDifferentialLendingRate < value.minDifferentialLendingRate){
    //   setDDLRError("Default Interest Rate must not be less than Minimum Floating Interest Rate");
    //   setTimeout(clearError, 8000);
    // } else if(value.maxDifferentialLendingRate < value.minDifferentialLendingRate){
    //   setmaxDLRError("Maximum Floating Interest Rate must not be less than Minimum Floating Interest Rate");
    //   setTimeout(clearError, 8000);
    // }
    else if (
      value.isLinkedToFloatingInterestRates === "false" &&
      !value.interestRatePerPeriod
    ) {
      setIrpError("This field is required*");
      setTimeout(clearError, 2000);
    } else if (
      value.isLinkedToFloatingInterestRates === "false" &&
      !value.interestRateFrequencyType
    ) {
      setIrfError("This field is required*");
      setTimeout(clearError, 2000);
    } else if (!value.repaymentEvery) {
      setReError("This field is required*");
      setTimeout(clearError, 2000);
    } else {
      handleNext();
    }
  };

  const handleChargesChange = (e) => {
    const { name, value: fieldValue } = e.target
    setValues({
      ...value,
      [name]: [{ "id": fieldValue }]
    })
  }

  const NUMERIC_DASH_REGEX = /^[e+-.]+$/;

  return (
    <>
      <div className={classes.container}>
        <h3>
          Principal <span style={{ color: `${star}` }}>*</span>
          <Icon title={PRINCIPAL} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="principal"
          value={value.principal || ""}
          error={pError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.container}>
        <h3>
          Minimum Principal Amount <span style={{ color: `${star}` }}>*</span>
          <Icon title={MIN_PRINCIPAL} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="minPrincipal"
          value={value.minPrincipal || ""}
          error={minPError}
          inputProps={{ inputmode: "numeric", pattern: "[0-9]*" }}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.container}>
        <h3>
          Maximum Principal Amount <span style={{ color: `${star}` }}>*</span>
          <Icon title={MAX_PRINCIPAL} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="maxPrincipal"
          value={value.maxPrincipal || ""}
          error={maxPError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>

      <div className={classes.container}>
        <h3>
          Charges <span style={{ color: `${star}` }}>*</span>
          <Icon title={CHARGES} />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="charges"
          value={value && value.charges[0] && value.charges[0].id}
          onChange={handleChargesChange}
          error={chargesError}
        >
          <MenuItem value={0}>Select Charge</MenuItem>
          {chargesList && chargesList.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>

      <div className={classes.container}>
        <h3>
          Number Of Repayments <span style={{ color: `${star}` }}>*</span>
          <Icon title={NO_OF_REPAYMENTS} />
        </h3>
        <Input
          label=""
          // disabled
          onChange={handleInputChange}
          type="number"
          name="numberOfRepayments"
          value={value.numberOfRepayments || ""}
          error={norError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      {/* <div className={classes.container}>
        <h3>Is linked to Floating Interest Rate</h3>
        <MuiRadioGroup
          row
          name="isLinkedToFloatingInterestRates"
          value={value.isLinkedToFloatingInterestRates}
          onChange={handleInputChange}
        >
          {LoanTermsOptions.islinkedToFloating.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.name}
              control={<Radio />}
              label={item.name}
            />
          ))}
        </MuiRadioGroup>
      </div> */}
      {/* {value.isLinkedToFloatingInterestRates === "true" ? (
        <>
          <div className={classes.container}>
            <h3>
              Floating Interest Rate
              <Icon title={FLOATING_INT} />
            </h3>
            <MuiSelect
              className={classes.textField}
              variant="outlined"
              labelId=""
              name="floatingRatesId"
              value={value.floatingRatesId}
              onChange={handleInputChange}
            >
              {floatingInterest.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className={classes.container}>
            <h3>Differential Rate <span style={{ color: `${star}` }}>*</span> </h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="number"
              name="interestRateDifferential"
              value={value.interestRateDifferential || ""}
              error={IRDError}
            />
          </div>
          <div className={classes.container}>
            <h3>
              Default Floating Interest Rate <span style={{ color: `${star}` }}>*</span>
              <Icon title="Number Of Repayments allowed for the loan Product" />
            </h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="number"
              name="defaultDifferentialLendingRate"
              value={value.defaultDifferentialLendingRate || ""}
              error={DDLRError}
            />
          </div>
          <div className={classes.container}>
            <h3>
              Minimum Floating Interest Rate <span style={{ color: `${star}` }}>*</span>
              <Icon title="Number Of Repayments allowed for the loan Product" />
            </h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="number"
              name="minDifferentialLendingRate"
              value={value.minDifferentialLendingRate || ""}
              error={MDLRError}
            />
          </div>
          <div className={classes.container}>
            <h3>
              Maximum Floating Interest Rate <span style={{ color: `${star}` }}>*</span>
              <Icon title={NO_OF_REPAYMENTS} />
            </h3>
            <Input
              label=""
              onChange={handleInputChange}
              type="number"
              name="maxDifferentialLendingRate"
              value={value.maxDifferentialLendingRate || ""}
              error={maxDLRError}
            />
          </div>
          <div className={classes.container}>
            <h3>Is Floating Calculation Allowed?</h3>
            <MuiRadioGroup
              row
              name="isFloatingInterestRateCalculationAllowed"
              value={value.isFloatingInterestRateCalculationAllowed}
              onChange={handleInputChange}
            >
              {LoanTermsOptions.islinkedToFloating.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </MuiRadioGroup>
          </div>
        </>
      ) : ( */}
      {/* <> */}
      <div className={classes.container}>
        <h3>
          Nominal Interest Rate <span style={{ color: `${star}` }}>*</span>
          <Icon title={NOMINAL_INT} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="interestRatePerPeriod"
          value={value.interestRatePerPeriod || ""}
          error={irpError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.container}>
        <h3>
          Interest Rate Frequency <br /> Type <span style={{ color: `${star}` }}>*</span>
          <Icon title="The Frequency at which interest rate changes" />
        </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="interestRateFrequencyType"
          value={value.interestRateFrequencyType}
          onChange={handleInputChange}
          error={irfError}
        >
          {LoanTermsOptions.interestRatePerPeriodOptions.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>
      {/* </>
      )} */}
      <div className={classes.container}>
        <h3>
          Repaid Every <span style={{ color: `${star}` }}>*</span>
          <Icon title={REPAID_EVERY} />
        </h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="repaymentEvery"
          value={value.repaymentEvery || ""}
          error={reError}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.container}>
        <h3>Repayment Frequency Type <span style={{ color: `${star}` }}>*</span> </h3>
        <MuiSelect
          className={classes.textField}
          variant="outlined"
          labelId=""
          name="repaymentFrequencyType"
          value={value.repaymentFrequencyType}
          onChange={handleInputChange}
        >
          {LoanTermsOptions.repaymentFrequencyType.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MuiSelect>
      </div>
      <div className={classes.container}>
        <h3>Minimum days between disbursal and first repayment date</h3>
        <Input
          label=""
          onChange={handleInputChange}
          type="number"
          name="minimumDaysBetweenDisbursalAndFirstRepayment"
          value={value.minimumDaysBetweenDisbursalAndFirstRepayment || ""}
          onKeyDown={(event) => {
            if (NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, width: 750 }}>
        <Button className={classes.buttons} text="Back" onClick={handleBack}>
          BACK
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          className={classes.buttons}
          text="Next"
          onClick={submitFormData}
        >
          NEXT
        </Button>
      </Box>
    </>
  );
}

export default LoanTerms;
