import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import DataService from "../services/DataService";


export const getLoanApplicationList = createAsyncThunk(
  "loanApplicationsList",
  async ({ pageNum, searchQry }) => {
    const url = `/applications?page=${pageNum}&limit=10&searchqry=${searchQry}`
    const res = await DataService.get(url);
    // console.log("response", res)
    return res.data
  }
);

export const getRejectedApplicationList = createAsyncThunk(
  "rejectedApplicationsList",
  async ({ pageNum, searchQry }) => {
    const url = ``;
    const res = await DataService.get(url);
    return res.data
  }
)

export const getIncompleteApplicationList = createAsyncThunk(
  "incompleteApplicationsList",
  async ({ pageNum, searchQry }) => {
    const url = `/loans/incomplete-applications?page=${pageNum}&limit=10&searchqry=${searchQry}`;
    const res = await DataService.get(url);
    // console.log("response", res)
    return res.data
  }
)

export const getLoanApplicationDetails = createAsyncThunk(
  "loanApplicationsDetails",
  async (id, thunkAPI) => {
    const url = `/applications/${id}`
    const res = await DataService.get(url);
    return res.data.data
  }
);

export const cibilData = createAsyncThunk(
  "cibilData",
  async (cibil_data, thunkAPI) => {
    const url = `/cibil/decentro-credit-report`
    const res = await DataService.post(url, cibil_data);
    return res.data.data
  }
);

export const businessReportData = createAsyncThunk(
  "businessReportData",
  async (id) => {
    const url = `/cibil/ecaps-businerss-report/${id}`
    const res = await DataService.get(url)
    return res.data
  }
);

export const getRejectedDropdownList = createAsyncThunk(
  "rejectDropdown",
  async() => {
    const url = `/documents/reasontypes`
    const res = await DataService.get(url)
    // console.log("Dropdowns action", res)
    return res.data
  }
)

export const nbfcApprove = createAsyncThunk(
  "nbfcApprove",
  async (data, thunkAPI) => {
    try {
      const url = `/applications/loan-approval`
      const res = await DataService.post(url, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nbfcReject = createAsyncThunk(
  "nbfcReject",
  async (data, thunkAPI) => {
    try {
      const url = `/applications/loan-reject`
      const res = await DataService.post(url, data);
      return res.data.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const DocumentVerification = createAsyncThunk(
  "approveDocument",
  async (state) => {
    const { index, ...data } = state;
    const url = 'documents/change-status'
    const res = await DataService.post(url, data)
    return state;
  }
)

export const reset_message = createAsyncThunk(
  "resetmessage",
  async () => {
    return;
  }
)

export const resetStatus = createAsyncThunk(
  "resetStatus",
  async (state) => {
    return state;
  }
)


const initialState = {
  loanApplications: [],
  rejApplications: [],
  rejReasons: [],
  incompApplications: [],
  applicDetails: [],
  kycDetails: [],
  approvedLoan: [],
  loanData: [],
  loanProductsList: [],
  clientLoan: [],
  nbfcApprovedClientData: [],
  messageLoan: '',
  error: '',
  success: "",
  loading: true,
  appn_appr_rej_loader: false,
  modalStatus: false,
  cibil_data: "",
  buss_report_data: {},
};

const loanApplicationSlice = createSlice({
  name: "loanApplication",
  initialState,
  prepare: (text) => {
    const id = nanoid()
  },

  extraReducers: {
    [getLoanApplicationList.pending]: (state, action) => {
      state.loading = true;
    },
    [getLoanApplicationList.fulfilled]: (state, action) => {
      state.loanApplications = action.payload.data
      state.totalCount = action.payload.totalItmes
      state.loading = false
    },
    [getLoanApplicationList.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [getRejectedApplicationList.pending]: (state, action) => {
      state.loading = true;
    },
    [getRejectedApplicationList.fulfilled]: (state, action) => {
      // state.rejApplications = action.payload.data
      // state.totalCount = action.payload.totalItmes
      // state.loading = false
    },
    [getRejectedApplicationList.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [getIncompleteApplicationList.pending]: (state, action) => {
      state.loading = true;
    },
    [getIncompleteApplicationList.fulfilled]: (state, action) => {
      // console.log("Act", action)
      state.incompApplications = action.payload.data
      state.totalCount = action.payload.totalItmes
      state.loading = false
    },
    [getIncompleteApplicationList.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    [getLoanApplicationDetails.fulfilled]: (state, action) => {
      state.applicDetails = action.payload
    },
    [cibilData.fulfilled]: (state, action) => {
      state.cibil_data = action.payload
    },
    [businessReportData.fulfilled]: (state, action) => {
      state.buss_report_data = action.payload.data
    },
    [getRejectedDropdownList.fulfilled]: (state, action) => {
      // console.log("Dropdowns reducer", action)
      state.rejReasons = action.payload.data
    },
    [nbfcApprove.pending]: (state) => {
      state.appn_appr_rej_loader = true;
    },
    [nbfcApprove.fulfilled]: (state, action) => {
      console.log('action', action)
      state.appn_appr_rej_loader = false;
      state.message = action.payload.message;
      state.success = action.payload.success;
      // const index = state.loanApplications.findIndex(item => item.loanapproval_id === action.payload.data.loanapproval_id);
      // state.loanApplications[index].statusId = 17
      state.loanApplications = state.loanApplications.filter(item => item.loanapproval_id != action.payload.loanapproval_id)
    },
    [nbfcApprove.rejected]: (state, action) => {
      state.appn_appr_rej_loader = false;
      state.message = action.payload.message;
      state.success = action.payload.success;
      // const index = state.loanApplications.findIndex(item => item.loanapproval_id === action.payload.data.loanapproval_id);
      // state.loanApplications[index].statusId = 17
      // state.loanApplications = state.loanApplications.filter(item => item.loanapproval_id != action.payload.loanapproval_id)
    },
    [nbfcReject.pending]: (state) => {
      state.appn_appr_rej_loader = true;
    },
    [nbfcReject.fulfilled]: (state, action) => {
      state.appn_appr_rej_loader = false;
      state.message = action.payload.message;
      state.success = action.payload.success;
      state.loanApplications = state.loanApplications.filter(item => item.loanapproval_id !== action.payload.loanapproval_id)
    },
    [nbfcReject.rejected]: (state, action) => {
      state.appn_appr_rej_loader = false;
      state.message = action.payload.message;
      state.success = action.payload.success;
      // const index = state.loanApplications.findIndex(item => item.loanapproval_id === action.payload.data.loanapproval_id);
      // state.loanApplications[index].statusId = 17
      // state.loanApplications = state.loanApplications.filter(item => item.loanapproval_id != action.payload.data.loanapproval_id)
    },
    [reset_message.fulfilled]: (state, action) => {
      state.success = "";
      state.message = "";
      state.applicDetails = "";
    },
    [resetStatus.fulfilled]: (state, action) => {
      state.modalStatus = action.payload;
    },
    [DocumentVerification.fulfilled]: (state, action) => {
      const index = state.applicDetails.documets[action.payload.index].findIndex(item => item._id === action.payload.documentId);
      state.applicDetails.documets[action.payload.index][index].status = action.payload.status
      const checkVerification = [];
      Object.keys(state.applicDetails.documets).map((item) => {
        if (Array.isArray(state.applicDetails.documets[item])) {
          state.applicDetails.documets[item].map((item) => { checkVerification.push(item.status === "uploaded" ? false : true) });
        }
      })
      if (!checkVerification.includes(false)) {
        state.applicDetails.documets.all_docs_verified = true;
      }
    },

  },
});

const { reducer } = loanApplicationSlice;

export default reducer;