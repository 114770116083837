import React, { useEffect, useState } from "react";
import { TableBody, TableCell, TableRow, Button, TextField, IconButton } from "@material-ui/core";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { SearchOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import { TXT_REPAYMENT, TXT_HOME } from "../../utils/header";
import ProgressSpinner from "../common/ProgressSpinner";
import HeadCells from "../common/HeadCells";
import { getRepaymentsList, getRepaymentsDataExcel } from "../../slice/repayments";
import DownloadIcon from '@mui/icons-material/Download';
import useExcel from "../common/useExcel";
import { DatePicker } from 'antd';
import moment from "moment";
import { disableUpcomingDates } from "../../utils/disableDates";
import debounce from "lodash/debounce";
import { useMemo } from "react";
const Repayment = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;
    const sharedClasses = useSharedClass();
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [fromDate, setFromDate] = useState(`${today}`);
    const [toDate, setToDate] = useState(`${today}`);
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;
    const { repaymentsList, totalCount, isLoading } = useSelector((store) => store.repayments);
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(repaymentsList, HeadCells.repaymentHead, totalCount);
    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }
    const excelData = useExcel();
    useEffect(() => {
        const data = {
        searchQry: search,
        pageNum: pageNo,
        fromDate,
        toDate,
        };
        dispatch(getRepaymentsList(data));
        setShowPageNtn(true);
    }, [dispatch, fromDate, toDate, pageNo, search]);
    let serialNo = PAGE_SIZE * slNo;
    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQry: search,
            pageNum: pageNo,
            fromDate,
            toDate,
            totalCount
        };
        dispatch(getRepaymentsDataExcel(data))
        .then(res=>{
            if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "Tranche Id" : item.trancheid,
                    "Name" : item.name,
                    "Amount" : item.amount,
                    "Bank Ref Num" : item.bank_reference_number,
                    "Principal" : item.principal_portion,
                    "Interest" : item.interest_portion,
                    "Penalty" : item.penalty,
                    "Transaction Date" : item.transaction_date,
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'RepaymentListSheet', 'repaymentlist.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }

    return (
        <>
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_REPAYMENT}
                subheader={`${TXT_HOME}/${TXT_REPAYMENT}`}
            />
            <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                <TextField
                    size="small"
                    className={sharedClasses.lpsearchTextBar}
                    //value={search}
                    onChange={debouncedResults}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Name"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                    endAdornment: (
                        <IconButton>
                        <SearchOutlined
                            className={sharedClasses.searchIconColour}
                        />
                        </IconButton>
                    ),
                    }}
                />
                </div>
                <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                <RangePicker
                    placeholder={["From Date", "To Date"]}
                    defaultValue={[moment(`${today}`, dateFormat), moment(`${today}`, dateFormat)]}
                    format={dateFormat}
                    className={sharedClasses.datePicker}
                    onChange={onDateChange}
                    disabledDate={disableUpcomingDates}

                />
                <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.download}
                    onClick={downloadExcelsheet}
                    disabled = {totalCount === 0 ? true : false}
                    endIcon={<DownloadIcon />}
                >
                    Download Ledger
                </Button>
                </div>
            </article>
            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {isLoading ? (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={8} align='center'>
                        <ProgressSpinner />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                    {
                        (repaymentsList.length !== 0) ?
                        (
                            repaymentsList &&
                            repaymentsList.map((item, key) => {
                            serialNo++;
                            return (
                                <TableRow key={key.toString()}>
                                <TableCell className={sharedClasses.tableCell}>
                                    {serialNo}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.trancheid}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.name}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.amount).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.bank_reference_number}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.principal_portion).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.interest_portion).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.penalty).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.transaction_date}
                                </TableCell>
                                </TableRow>
                            );
                            })
                        ) : (
                            <TableRow>
                            <TableCell colSpan={8} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                )}
                </TblContainer>
                {showPageNtn ? <TblPagination /> : ""}
            </section>
            </div>
        </div>
        </>
    );
};
export default Repayment;