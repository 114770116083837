import React, { useContext } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { CustomerData } from './LoanSanctioned'
import ModalImage from "react-modal-image";
import click_here from './click_here.svg'
import useStyles from './LoanAppStyles';
import { Button } from 'antd';
import { ImageList } from '@material-ui/core';
import { getFileExtension } from '../../utils/getExtension';

const Kyc = () => {

    const loanSanctionedView = useContext(CustomerData)
    const classes = useStyles()
    const BASE_URL = process.env.REACT_APP_API_BASE_URL

    return (
        <>
            <Paper
                variant="outlined"
                className={classes.documentsTabsHead}
            >
                <Grid container spacing={1} justify="center" alignItems="center">
                    {
                        loanSanctionedView && loanSanctionedView.documets['2']?.map((item) => {
                        const extension = getFileExtension(item.fileName)
                            return (
                                <>
                                    <Grid item md={2} alignItem="center" display="flex" justifyContent={'center'} marginBottom={1}>
                                        {
                                            extension === 'pdf' ?
                                            <a href={item.fileName} target="_blank" rel="noopener noreferrer">
                                                <Button type="primary">View File</Button>
                                            </a>
                                            :
                                            <ImageList sx={{ mt: "13px", ml: "31px" }}>
                                                <ModalImage
                                                    smallSrcSet={click_here}
                                                    hideDownload={true}
                                                    hideZoom={true}
                                                    large={item.fileName}
                                                    alt="Uploaded doc"
                                                />
                                            </ImageList>
                                        }
                                    </Grid>
                                    <Grid item md={3} alignItem="center" marginBottom={1}/>
                                    <Grid item md={6} container justifyContent={"center"} alignItems={"center"} marginBottom={1}>
                                        {/* <Typography variant="subtitle1" fontWeight="bold">
                                            {item.status}
                                        </Typography> */}
                                    </Grid>
                                </>
                            )}
                        )
                    }
                </Grid>
            </Paper>
        </>
    )
}

export default Kyc