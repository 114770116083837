import React, { useState, useEffect } from 'react'
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import PageHeader from '../Sharedfeatures/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { TXT_HOME, TXT_LOAN_CLOSED } from '../../utils/header';
import { TextField, TableBody, TableCell, IconButton, TableRow, Button } from "@material-ui/core";
import ProgressSpinner from '../common/ProgressSpinner';
import { getLoanClosedList, getLoanClosedExcel } from '../../slice/loanClosed';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import useExcel from '../common/useExcel'
import { DatePicker } from 'antd';
import debounce from "lodash/debounce";
import { useMemo } from "react";
const LoansClosed = () => {

    //For getting today and yesterday date
    let today = new Date();
    //let yesterday = new Date();
    let today_dd = String(today.getDate()).padStart(2, '0');
    //let yest_dd = String(today.getDate() - 1).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + today_dd;
    //yesterday = yyyy + '-' + mm + '-' + yest_dd;

    const sharedClasses = useSharedClasses();
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const { RangePicker } = DatePicker;

    const { loanClosedList, totalCount, setLoading, } = useSelector((store) => store.loanClosed);
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(loanClosedList, HeadCells.loanClosedHead, totalCount);

    const onDateChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    }
    const excelData = useExcel()
    useEffect(() => {
        const data = {
            searchQry: search,
            pageNum: pageNo,
            fromDate,
            toDate,
        };
        dispatch(getLoanClosedList(data));
        setShowPageNtn(true);
    }, [dispatch, fromDate, toDate, pageNo, search]);

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQry: search,
            pageNum: pageNo,
            fromDate,
            toDate,
            totalCount,
        };
        dispatch(getLoanClosedExcel(data))
        .then(res=>{
            if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "RT/DT Code" : item.customerid,
                    "Loan A/C No" : item.loanaccountNumber,
                    "Name" : item.name,
                    "Loan Name" : item.loanName,
                    "Interest Rate" : item.interest_rate,
                    "Tenure" : item.tenure,
                    "Closed Date" : moment(item.closed_on).format('DD MMMM YYYY'),
                    "Status" : item.status
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'LoanClosedSheet', 'loanclosedlist.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }

    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_LOAN_CLOSED}
                        subheader={`${TXT_HOME}/${TXT_LOAN_CLOSED}`}
                    />
                    <article className={sharedClasses.searchAddParent}>
                        <div className={sharedClasses.lpsearchBar}>
                            <TextField
                                size="small"
                                className={sharedClasses.lpsearchTextBar}
                                //value={search}
                                onChange={debouncedResults}
                                id="standard-bare"
                                variant="outlined"
                                placeholder="Search Name or RT/DT Code"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <SearchOutlined
                                                className={sharedClasses.searchIconColour}
                                            />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>

                        <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', flexWrap:'wrap', gap:'20px'}}>
                            <RangePicker
                                placeholder={["From Date", "To Date"]}
                                format={dateFormat}
                                className={sharedClasses.datePicker}
                                onChange={onDateChange}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                className={sharedClasses.download}
                                onClick={downloadExcelsheet}
                                disabled = {totalCount === 0 ? true : false}
                                endIcon={<DownloadIcon />}
                            >
                                Download Ledger
                            </Button>
                        </div>
                    </article>

                    <section className={sharedClasses.mainSection}>
                        <TblContainer>
                            <TblHead />
                            {setLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={9} align='center'>
                                            <ProgressSpinner />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        (loanClosedList.length !== 0) ?
                                            (loanClosedList &&
                                                loanClosedList.map((item, index) => {
                                                    serialNo++;
                                                    return (
                                                        <>
                                                            <TableRow key={item.id.toString()}>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {serialNo}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.customerid}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.loanaccountNumber}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.loanName}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.interest_rate}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.tenure}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {moment(item.closed_on).format('DD MMMM YYYY')}
                                                                </TableCell>
                                                                <TableCell className={sharedClasses.tableCell}>
                                                                    {item.status}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={9} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                                                </TableRow>
                                            )
                                    }
                                </TableBody>
                            )}
                        </TblContainer>
                        {showPageNtn ? <TblPagination /> : ""}
                    </section>
                </div>
            </div>
        </>
    )
}

export default LoansClosed