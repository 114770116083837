import React from 'react'
import { Box, IconButton, Modal } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import DefaultedStyle from './LoansDefaultedStyles';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const ViewModalWrap = ({ openViewData, setOpenViewData }) => {
    const classes = DefaultedStyle()

    return (
        <Modal open={openViewData !=='' ? true : false}>
            <Box className={classes.modalwrap}>

                <Box className={classes.iconwrap}>
                    <IconButton onClick={()=> setOpenViewData('')}>
                        <AiOutlineClose />
                    </IconButton>
                </Box>
                <div className={classes.viewMainContentWrap}>
                    <div className={classes.mainTitle}>Loan Defaulted Details</div>
                    <Grid container spacing={6} className={classes.gridContainer}>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>DT/RT Code:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.customerid}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Tranche ID:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.trancheid}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Name:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.name}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Loan Name:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.loanName}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Amount:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{Number(openViewData?.amount_sanctioned).toFixed(2)}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Interest:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.interest_rate}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Payback Amount:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{Number(openViewData?.paybackAmount).toFixed(2)}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Interest Amount:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{Number(openViewData?.interest).toFixed(2)}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Penalty:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{Number(openViewData?.penalty).toFixed(2)}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Payback Date:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{moment(openViewData?.payback_date).format('DD MMMM YYYY')}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>DPD</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.dpd}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Tenure</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.tenure}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewLabel}>Status:</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.viewValue}>{openViewData?.status}</span>
                        </Grid>
                        
                    </Grid>
                </div>
            </Box>
        </Modal>
    )
}

export default ViewModalWrap
