import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableCell, IconButton, TextField, Button } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { TXT_BORROWER, TXT_HOME } from "../../utils/header";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import PageHeader from "../Sharedfeatures/PageHeader";
import { getBorrowersList, getBorrowersListExcel } from '../../slice/borrowerslist'
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import ProgressSpinner from "../common/ProgressSpinner";
import debounce from "lodash/debounce";
import { useMemo } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import useExcel from "../common/useExcel";

const BorrowersList = () => {
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch()
    const { isLoading, borrowersList, totalCount } = useSelector((store) => store.borrowers)
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, } = useTable(borrowersList, HeadCells.borrowersHead, totalCount);
    const [search, setSearch] = useState("")
    const [showPageNtn, setShowPageNtn] = useState(false);
    const moment = require("moment");
    const excelData = useExcel();
    
    useEffect(() => {
        const data = {
        searchQry: search,
        pageNum: pageNo,
        };
        dispatch(getBorrowersList(data))
        setShowPageNtn(true);
    }, [dispatch, pageNo, search])

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    const downloadExcelsheet = ()=>{
        const data = {
            searchQry: search,
            totalCount,
        };
        dispatch(getBorrowersListExcel(data))
        .then(res=>{
            if(res.payload.success === true){
            let excelsheetdata = []
            res.payload.data?.map(item=>{
                excelsheetdata.push({
                    "User Id" : item.userid,
                    "Borrower Name" : item.borrower_name,
                    "Acc No" : item.loanaccount_number,
                    "Loan Gen." : item.loan_generated,
                    "Loan Sanc." : item.loan_sanctioned,
                    "Loan Disb." : Number(item.loan_disbursed).toFixed(2),
                    "Int Paid" : Number(item.interest_paid).toFixed(2),
                    "Last Repymt" : item.last_repayment === "No repayments" ? item.last_repayment : moment(item.last_repayment).format("DD-MM-YYYY"),
                })
            })
            excelData.generateExcelFile(excelsheetdata, 'BorrowersListSheet', 'borrowerslistsheet.xlsx')
            }
        })
        .catch(err=>{
            console.error(err);
        })
    }
    return (
        <>
        <div className={sharedClasses.mainDiv}>
            <div className={sharedClasses.pageContent}>
            <PageHeader
                header={TXT_BORROWER}
                subheader={`${TXT_HOME}/${TXT_BORROWER}`}
            />

            <article className={sharedClasses.searchAddParent}>
                <div className={sharedClasses.lpsearchBar}>
                    <TextField
                        size="small"
                        className={sharedClasses.searchTextBar}
                        //value={search}
                        onChange={debouncedResults}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Name"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                        endAdornment: (
                            <IconButton>
                            <SearchOutlined
                                className={sharedClasses.searchIconColour}
                            />
                            </IconButton>
                        ),
                        }}
                    />
                </div>
                <Button
                    variant="outlined"
                    color="primary"
                    className={sharedClasses.download}
                    onClick={downloadExcelsheet}
                    disabled = {totalCount === 0 ? true : false}
                    endIcon={<DownloadIcon />}
                >
                    Download
                </Button>
            </article>
            <section className={sharedClasses.mainSection}>
                <TblContainer>
                <TblHead />
                {
                    isLoading ? (
                    <TableBody>
                        <TableRow>
                        <TableCell colSpan={10} align='center'>
                            <ProgressSpinner />
                        </TableCell>
                        </TableRow>
                    </TableBody>
                    ) : (
                    <TableBody>
                        {
                        (borrowersList.length !== 0) ? (
                            borrowersList &&
                            borrowersList.map((item, key) => {
                            serialNo++;
                            key++;
                            return (
                                <TableRow key={key.toString()}>
                                <TableCell>{serialNo}</TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.userid}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.borrower_name}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.loanaccount_number}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.loan_generated}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.loan_sanctioned}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.loan_disbursed).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.interest_paid).toFixed(2)}
                                </TableCell>
                                <TableCell className={sharedClasses.tableCell}>
                                    {item.last_repayment === "No repayments" ? item.last_repayment : moment(item.last_repayment).format("DD-MM-YYYY")}
                                </TableCell>
                                {/* <TableCell className={sharedClasses.tableCell}>
                                    {Number(item.remaining_amount).toFixed(2)}
                                </TableCell> */}
                                </TableRow>
                            );
                            })
                        ) : (
                            <TableRow>
                            <TableCell colSpan={10} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                            </TableRow>
                        )
                        }
                    </TableBody>
                    )
                }

                </TblContainer>
                {showPageNtn ? <TblPagination /> : ""}
            </section>
            </div>
        </div>
        </>
    );
};

export default BorrowersList;
