import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import PageHeader from "../Sharedfeatures/PageHeader";
import { TXT_CHARGE_DETAILS, TXT_HOME, TXT_CHARGES, BACK_BUTTON } from '../../utils/header';
import { CHARGE_LIST } from '../../utils/url';
import { useDispatch, useSelector } from 'react-redux';
import { getChargeDetails } from '../../slice/overduecharges';
import { Container, Grid, Paper } from "@material-ui/core";
import GridView from './GridView';

const ChargeDetails = () => {

    const { id } = useParams();
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getChargeDetails(id))
    }, [])

    const { chargeDetails } = useSelector((store) => store.chargesOverdue);

    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_CHARGE_DETAILS}
                        subheader={`${TXT_HOME}/${TXT_CHARGES}/${TXT_CHARGE_DETAILS}`}
                        icon={BACK_BUTTON}
                        link={CHARGE_LIST}
                    />

                    <Paper className={sharedClasses.detailsCard}>

                        <GridView
                            leftHeader="Name"
                            leftDetail={chargeDetails.name}
                            rightHeader="Charge Applies To"
                            rightDetail={chargeDetails && chargeDetails.chargeAppliesTo && chargeDetails.chargeAppliesTo.value}
                        />
                        <GridView
                            leftHeader="Is Penalty"
                            leftDetail={chargeDetails.penalty === true ? 'True' : 'False'}
                            rightHeader="Status"
                            rightDetail={chargeDetails.active === true ? 'True' : 'False'}
                        />
                    </Paper>


                </div>
            </div>
        </>
    )
}

export default ChargeDetails
