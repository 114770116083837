import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Select as MuiSelect,
  MenuItem,
  Button,
  Box,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { star } from "../../utils/index";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../Sharedfeatures/useForm";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Input from "../controls/Input";
import validator from "validator";
import Icon from "../common/Icon";
import { PDT_NAME, SHORT_NAME, CO_LENDING, CLOSE_DATE } from "../../utils/info";
import LoanSettingsOptions from "./Options/LoanSettingsOptions";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px",
        width: "130%",
        marginTop: "14px",
    },
}));

function LoanNameDetails({ handleInputChange, value, handleNext }) {
    const classes = useStyles();
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const { id } = useParams();
    // const { loanDetails, success } = useSelector((state) => state.loan);
    // const { partnerList } = useSelector((state) => state.partner);
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [colendingError, setColendingError] = useState("");
    const [loanNameError, setLoanNameError] = useState("");
    const [loanShortNameError, setLoanShortNameError] = useState("");

    // const validate = (fieldValues = value) => {
    //   let temp = { ...errors };
    //   if ("name" in fieldValues)
    //     temp.name = fieldValues.name ? "" : "This field is required.";
    //   if ("shortName" in fieldValues)
    //     temp.shortName =
    //       fieldValues.shortName.length === 0 ? "" : "This field is required";
    //   if ("shortName" in fieldValues)
    //     temp.shortName =
    //       fieldValues.shortName.length >= 4 ? "Cannot exceed 3 letters" : "";
    //   setErrors({
    //     ...temp,
    //   });
    //   if (fieldValues == value) return Object.values(temp).every((x) => x == "");
    // };

    // const {
    //   values,
    //   setValues,
    //   errors,
    //   setErrors,
    //   // handleInputChange,
    //   resetForm,
    // } = useForm();

    function clearError() {
        setColendingError("")
        setLoanNameError("");
        setLoanShortNameError("");
    }

    const submitFormData = (e) => {
        e.preventDefault();
        if (validator.isEmpty(value.colending)) {
            setColendingError("This field is required*");
            setTimeout(clearError, 2000);
        }
        else if (validator.isEmpty(value.name)) {
        setLoanNameError("This field is required*");
        setTimeout(clearError, 2000);
        } else if (validator.isEmpty(value.shortName)) {
        setLoanShortNameError("This field is required*");
        setTimeout(clearError, 2000);
        } else if (value.shortName.length > 4) {
        setLoanShortNameError("Short name cannot exceed 4 letters*");
        setTimeout(clearError, 2000);
        } else {
        handleNext();
        }
    };
    // const today = new Date();
    return (
        <>
            <div className={classes.container}>
                    <h3>
                    Is this Co-lending  product <span style={{ color: `${star}` }}>*</span>
                    <Icon title={CO_LENDING} />
                    </h3>
                    <FormControl error={colendingError ? true : false} variant="standard">
                        <MuiRadioGroup
                            row
                            name="colending"
                            value={value.colending}
                            onChange={handleInputChange}
                        >
                            {LoanSettingsOptions.isCoLending.map((item) => (
                                <FormControlLabel
                                key={item.id}
                                value={item.id}
                                control={<Radio />}
                                label={item.name}
                                />
                            ))}
                        </MuiRadioGroup>
                        <FormHelperText>{colendingError}</FormHelperText>
                    </FormControl>
            </div>
            <div className={classes.container}>
                <h3>
                Product name <span style={{ color: `${star}` }}>*</span>
                <Icon title={PDT_NAME} />
                </h3>
                <div>
                <Input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={value.name || name || ""}
                    label=""
                    error={loanNameError}
                />
                </div>
            </div>
            <div className={classes.container}>
                <h3>
                Short name <span style={{ color: `${star}` }}>*</span>
                <Icon title={SHORT_NAME} />
                </h3>
                <Input
                label=""
                onChange={handleInputChange}
                type="text"
                name="shortName"
                value={value.shortName || shortName || ""}
                error={loanShortNameError}
                />
            </div>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', pt: 2, width: 700 }}>
                <Button
                className={classes.nextBtn}
                variant="contained"
                color="primary"
                data-testid="nextBtn"
                disabled
                >
                BACK
                </Button>
                {/* <Box sx={{ flex: "1 1 auto" }} />
                <Box sx={{ flex: "1 1 auto" }} /> */}
                <Button
                className={classes.nextBtn}
                variant="contained"
                color="primary"
                data-testid="nextBtn"
                onClick={submitFormData}
                >
                NEXT
                </Button>
            </Box>
        </>
    );
}

export default LoanNameDetails;
