export const CO_LENDING = "Check  'Yes' if it is a co-lending loan product otherwise check 'No'.";
export const PDT_NAME = "The product name is a unique identifier for the lending product.";
export const SHORT_NAME = "The short name is a unique identifier for the lending product.";
export const START_DATE = 'The date that the loan product will be active and available to clients. If blanck the loan product will be active as soon as it is created'
export const CLOSE_DATE = 'The date that the loan product will be inactive and available to clients. If blanck the loan product will never become inactive';
export const CURRENCY = 'The currency in which the loan will be disbursed';
export const DECI_PLACES = 'The number of decimal places to be used to track and report on loans';
export const CURRENCY_MULTIPLES_OF = 'You can enter multiples of currency value. For ex: If you put multiples of 100, the currency value will be rounded off to 200, 300,400 etc';
export const MAX_PRINCIPAL = "Maximum Principal Amount allowed for the loan Product"
export const MIN_PRINCIPAL = "Minimum Principal Amount allowed for the loan Product"
export const PRINCIPAL = "Principal Amount allowed for the loan Product"
export const CHARGES = "The charges specific to each loan product"
export const NO_OF_REPAYMENTS = "Number Of Repayments allowed for the loan Product"
export const FLOATING_INT = "These fields are used to define the minimum, default, maximum, and period for the floating interest rate allowed for the loan product. The minimum, default, and maximum floating interest rates are expressed as percentages."
export const NOMINAL_INT = "These fields are used to define the minimum, default, maximum, and period for the nominal interest rate allowed for the loan product. The minimum, default, and maximum nominal interest rates are expressed as percentages."
export const REPAID_EVERY = "These fields are input to calculating the repayment schedule for a loan account and are used to determine when payments are due."
export const AMORTIZATION = "The Amortization value is input to calculating the repayment amounts for repayment of the loan."
export const INTEREST_METHOD = "The Interest method value is input to calculating the payments amount for repayment of the loan. "
export const INT_CALC_PERIOD = "Daily - Will Calculate the interest on DAILY basis ex: Month of February has 28days and it will calculate interest for 28days, SAME AS REPAYMENT PERIOD- it calculates for the month,that is, 30days."
export const REPAYMENT_STRATEGY = "The repayment strategy determines the sequence in which each of the components is paid."
export const MORATORIUM = "The moratorium information will default from the loan product settings, but can be changed for this loan account"
export const INTEREST_FREE_PERIOD = "If the Interest Free Period is '4' and the client's Repayment Frequency is every week, then for the first four weeks the client need not to pay interest, he has to pay principle due for that week only."
export const ARREARS_TOLERANCE = "With 'Arrears tolerance' you can specify a tolerance amount and if the loan is behind (in arrears), but within the tolerance, it won't be classified as 'in arrears' and part of the portfolio at risk."
export const DAYS_IN_YEAR = "The setting for number of days in year to use to calculate interest"
export const DAYS_IN_MONTH = "Number of Days in Month"
export const OVERDUE_DAYS = "A loan is in arrears once the number of days entered into this field is exceeded. If this field is blank, the loan will be in arrears the day after a scheduled payment is missed. "
export const NPA_DAYS = "A loan is a NPA (non performing asset) once the number of days entered into this field is exceeded. If this field is blank, the loan will be an NPA the day after a scheduled payment is missed."
export const VAR_INSTALLMENT ="These fields are used to define the minimum, maximum gap that should be present between the installments for the loan product"
export const INT_RECALCULATION= "If you select None, next period(month or week) interest will be calculated on principle only. If you select Fee, next period interest will be recalculated based on Principle + Fee. If you select Interest, next period interest will be recalculated based on Principle + Interest. If you select 'Fee and Interest' next period interest will be recalculated based on Principle + Interest+ Fee"
export const FREQUENCY_RECALCULATE_OUTSTANDING= "Once the client makes advance repayments, his outstanding principle may be recalculated on a weekly, fortnightly, monthly or quarterly basis, thereby reducing the weekly, fortnightly, monthly outstanding balance."
export const FREQUENCY_INTERVAL_RECALCULATION ="This field accompanies the 'Frequency for recalculate outstanding principle' . Ex: selecting the interval as 1 and the frequency as monthly, would result in the outstanding balance (reduction) being calculated for every month."
export const MAX_DISBURSEMENTS ="The maximum number of disbursements allowed for a loan account."
export const MAX_OUTSTANDING_BAL ="The maximum outstanding loan account balance allowed at a point in time."
export const ENABLE_MULTIDISBURSAL = "Leave this checkbox unchecked if the loan is a single disburse loan. Check this checkbox if the loan is a multi disburse loan. See additional fields for additional information required for this type of loan."