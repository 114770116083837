import { Radio } from 'antd'

const DisbursedSection = ({disbursedValue,setDisbursedValue,setOpenDialogue}) => {
    const onChange = (e) => {
        setDisbursedValue(e.target.value);
        e.target.value === 'no' && setOpenDialogue('')
    };
    return (
        <div style={{display:'flex', gap:'20px', alignItems:'center'}}>
            <p style={{margin:0}}>Are you sure you want to mark  this loan as disbursed?</p>
            <Radio.Group onChange={onChange} value={disbursedValue}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
            </Radio.Group>
        </div>
    )
}

export default DisbursedSection