import { makeStyles } from "@material-ui/core";
const DefaultedStyle = makeStyles({
    modalwrap: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight:700,
        width:700,
        backgroundColor:'#ffffff',
        borderRadius: '15px',
        padding : '30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    iconwrap : {
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    
    viewMainContentWrap:{
        display :'flex',
        flexDirection : 'column',
        gap : '30px',
        justifyContent : 'center',
        alignItems : 'center',
        width: '100%'
    },
    mainTitle : {
        color: '#963460',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '36px',
        letterSpacing: '0.18px',
    },
   
    gridContainer:{
        maxHeight:600,
        overflowY :'scroll'
    },
    viewLabel:{
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'Arial',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.15px',
    },
    viewValue:{
        color: '#444445',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.15px',
        wordBreak : 'break-word'
    },
    amtWrap:{
        display:'flex',
        alignItems:'center',
        gap:20,
        flexWrap:'wrap'
    },
    amtSubWrap:{
        display:'flex',
        alignItems:'center',
        justifyContent:'start',
        gap:10
    }
})
export default DefaultedStyle