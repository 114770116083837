const loanHead = [
    { id: '#', label: '#' },
    { id: 'Loanproduct', label: 'Loan Product' },
    { id: 'shortName', label: 'Short Name' },
    { id: 'maxPrincipal', label: 'Max Principal' },
    { id: 'status', label: 'Status' },
    { id: 'Action', label: 'View Details' },
]

const chargesHead = [
    { id: '#', label: '#' },
    { id: 'name', label: 'Charge Name' },
    { id: 'chargeappliesto', label: 'Charge Applies To' },
    { id: 'isPenalty', label: 'Is Penalty' },
    { id: 'status', label: 'Status' },
    { id: 'details', label: 'Details' }
]

const preApprovedHead = [
    { id: '#', label: '#' },
    { id: 'userid', label: 'RT/DT Code' },
    { id: 'name', label: 'Name' },
    { id: 'loanproduct', label: 'Loan Product' },
    { id: 'amount', label: 'Amount' },
    { id: 'editamount', label: 'Edited Offer' },
    { id: 'editoffer', label: 'Edit Offer' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'action', label: 'Approve/Reject' },
]

const trancheDetailsHead = [
    { id: '#', label: '#' },
    { id: 'id', label: 'Tranche Id' },
    { id: 'dtrtcode', label: 'DT/RT Code' },
    { id: 'customername', label: 'Name' },
    { id: 'loan_amount', label: 'Amount' },
    { id: 'tranche_amount', label: 'Tranche Amount' },
    { id: 'expected_disbursal_date', label: 'Disbursal Date' },
    { id: 'payback_date', label: 'Payback Date' },
    { id: 'action', label: 'Action' },
]

const loanApplicationHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'customername', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'loansn', label: 'Email' },
    { id: 'loanproduct', label: 'Loan Product' },
    { id: 'amtoffer', label: 'Amount' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'details', label: 'Details' },
]

const rejectedApplicationsHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'customername', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'loansn', label: 'Email' },
    { id: 'loanproduct', label: 'Loan Product' },
    { id: 'amtoffer', label: 'Amount' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'rejdocs', label: 'Rejected Docs' },
]

const IncompleteApplicationsHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'customername', label: 'Name' },
    { id: 'fosname', label: 'Fos Name' },
    { id: 'phone', label: 'Phone' },
    // { id: 'loansn', label: 'Email' },
    { id: 'loanproduct', label: 'Loan Product' },
    { id: 'amtoffer', label: 'Amount' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'status', label: 'Status' },
]

const loanDisbursedHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'ac_no', label: 'Loan A/C No' },
    { id: 'emp_name', label: 'Name' },
    { id: 'loan_product', label: 'Loan Product' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'loan_disb', label: 'Amount' },
    { id: 'int_rate', label: 'Interest Rate' },
    { id: 'tranc_details', label: 'Tranche Details' },
    { id: "repayments", label: "Repayments" },
]

const loanSanctionedHead = [
    { id: 'slno', label: '#' },
    { id: 'rtdtcode', label: 'DT/RT Code' },
    { id: 'emp_name', label: 'Name' },
    { id: 'loan_name', label: 'Loan Product' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'loan_sac', label: 'Amount' },
    { id: 'int_rate', label: 'Interest Rate' },
    { id: 'tranc_details', label: 'Tranche Details' },
    { id: 'loan_st', label: 'Loan Details' },
    { id: 'docs', label: 'Documents' },
]

const tranche_list_client = [
    { id: "slno", label: "#" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "loanamt", label: "Amount" },
    { id: "trancheamt", label: "Tranche Amount" },
    { id: "disbursalDate", label: "Disbursal Date" },
    { id: "paybackDate", label: "Payback Date" },
    { id: "status", label: "Status" },
];

const repayment_details_modal = [
    { id: 'srno', label: '#' },
    { id: 'trnid', label: 'Tranche Id' },
    { id: 'amt', label: 'Amount' },
    { id: 'prncpl', label: 'Principal' },
    { id: 'int', label: 'Interest' },
    { id: 'penalty', label: 'Penalty' },
    { id: 'trndt', label: 'Transaction Date' },
]

const repaymentHead = [
    { id: "slno", label: "#" },
    { id: "trancheId", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "amount", label: "Amount" },
    { id: "bank_ref_num", label: "Bank Ref Num" },
    { id: "principal_portion", label: "Principal" },
    { id: "interest_portion", label: "Interest" },
    { id: "penalty", label: "Penalty" },
    { id: "transaction_date", label: "Transaction Date" },
]

const borrowersHead = [
    { id: "slno", label: "#" },
    { id: "userid", label: "User Id" },
    { id: "borrwr_name", label: "Borrower Name" },
    { id: "loan_acc_no", label: "Acc No" },
    { id: "loan_gener", label: "Loan Gen." },
    { id: "loan_sanc", label: "Loan Sanc." },
    { id: "loan_disb", label: "Loan Disb." },
    { id: "int_paid", label: "Int Paid" },
    { id: "last_repymt", label: "Last Repymt" },
    // { id: "bal_amt", label: "Bal Amt." },
]

const loanAccountHeads = [
    { id: "slno", label: "#" },
    { id: "acno", label: "A/C No:" },
    { id: "extid", label: "External Id" },
    { id: "loan", label: "Loan Product" },
    { id: "loanamt", label: "Loan Amount" },
    { id: "amtPaid", label: "Amount Paid" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
]

const loanStatementHead = [
    { id: 'disb_p', label: 'Disb. Princi.' },
    { id: 'paid_p', label: 'Paid Princi.' },
    { id: 'outst_p', label: 'Outst. Princi.' },
    { id: 'overd_p', label: 'Overdue Princi.' },
    { id: 'int_c', label: 'Int. Charged' },
    { id: 'int_p', label: 'Int. Paid' },
    { id: 'int_waived', label: 'Int. Waived' },
    { id: 'int_outst', label: 'Int. Outstanding' },
    { id: 'int_overd', label: 'Int. Overdue' },
    { id: 'charges_added', label: 'Charges Added (Int. + Charge)' },
    { id: 'exp_rept', label: 'Total Expected Repayment' },
]

const loanDefaultedHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheId", label: "Tranche Id" },
    { id: "cstmrname", label: "Name" },
    { id: "loanname", label: "Loan Name" },
    { id: "amt", label: "Amount" },
    { id: "payback_amt", label: "Payback Amount" },
    { id: "payback_date", label: "Payback Date" },
    { id: "dpd", label: "DPD" },
    { id: "view", label: "View" },
]

const loanClosedHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: 'ac_no', label: 'Loan A/C No' },
    { id: "cstmrname", label: "Name" },
    { id: "loanname", label: "Loan Name" },
    { id: "intrate", label: "Interest Rate" },
    { id: "tenure", label: "Tenure" },
    { id: "closed_dt", label: "Closed Date" },
    { id: "status", label: "Status" }
]

const upcomingRepaymentsHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheId", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "trnamount", label: "Tranche Amount" },
    { id: "loanamount", label: "Loan Amount" },
    { id: "payable", label: "Payable" },
    { id: "payback_dt", label: "Payback Date" },
    { id: "status", label: "Status" }
]

const tranchesListHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "RT/DT Code" },
    { id: "trancheId", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "trnamount", label: "Tranche Amount" },
    { id: "created_date", label: "Created On" },
    { id: "status", label: "Disbursal Status" },
    { id: "action", label: "Action" },
    { id: "view", label: "View" },

]
const virtualAccounttHead = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "DT/RT Code" },
    { id: "name", label: "Name" },
    { id: "trnamount", label: "Freezed Amount" },
    { id: "actions", label: "Actions" },
]
const inProgressRepayments = [
    { id: "slno", label: "#" },
    { id: "rtdtcode", label: "DT/RT Code" },
    { id: "trancheid", label: "Tranche Id" },
    { id: "name", label: "Name" },
    { id: "trancheamt", label: "Tranche Amount" },
    { id: 'loan_amt', label: 'Loan Amount' },
    { id: "payable", label: "Payable" },    
    { id: "paybackon", label: "Payback On" },
]

const HeadCells = {
    loanHead,
    chargesHead,
    preApprovedHead,
    trancheDetailsHead,
    loanApplicationHead,
    rejectedApplicationsHead,
    IncompleteApplicationsHead,
    loanDisbursedHead,
    loanSanctionedHead,
    tranche_list_client,
    repayment_details_modal,
    repaymentHead,
    borrowersHead,
    loanAccountHeads,
    loanStatementHead,
    loanDefaultedHead,
    loanClosedHead,
    upcomingRepaymentsHead,
    tranchesListHead,
    virtualAccounttHead,
    inProgressRepayments
}

export default HeadCells;