import React, { createContext, useState, useEffect } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TextField,
  IconButton,
} from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { Link } from "react-router-dom";
import {
  getLoanApplicationList,
  getLoanApplicationDetails,
  cibilData,
  reset_message,
  resetStatus,
  businessReportData,
} from "../../slice/loanapplication";
import { TXT_LOAN_APPLICATION, TXT_HOME } from "../../utils/header";
import { PAGE_SIZE } from "../../utils/variable";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import Popups from "./Stepper/Popup";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import { SearchOutlined } from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from "../common/useSnackbar";

import LoadingModal from "../Loans/LoadingModal";
import { useMemo } from "react";
import debounce from "lodash/debounce";

const CustomerData = createContext();

const LoanApplication = () => {
  
    const [showPageNtn, setShowPageNtn] = useState(false);
    const [search, setSearch] = useState("");

    const {
        loanApplications,
        applicDetails,
        totalCount,
        success,
        message,
        loading,
        modalStatus,
        appn_appr_rej_loader
    } = useSelector((state) => state.loanApplication); // applicDetails is used to use useContext
    
    const { TblContainer, TblHead, TblPagination, pageNo, slNo, handleChange } = useTable(
        loanApplications,
        HeadCells.loanApplicationHead,
        totalCount
    );
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch();
    const snackBar = useSnackbar();

    useEffect(() => {
        const data = {
        searchQry: search,
        pageNum: pageNo,
        };
        if(modalStatus === false){
            dispatch(getLoanApplicationList(data)); //to show loan applications list
            setShowPageNtn(true)
            dispatch(reset_message());
        }
    }, [dispatch, pageNo, search,modalStatus]);

    useEffect(() => {
        return (message && success) ?  snackBar.openSnackbar(message,success) : (message && success === false) ? snackBar.openSnackbar(message,success) : "";
        dispatch(reset_message());
    }, [message, success]);

    const handleClose = () => {
        dispatch(resetStatus(false));
    };
    const handleShowDetails = (item) => {
        dispatch(reset_message());
        dispatch(resetStatus(true));
        dispatch(getLoanApplicationDetails(item.loanapproval_id)); // to show the details of loan application in stepper
        const cibil_data = {
        user_id: item.id,
        pan: "CDYPJ8680E",
        loanApprovalId:item.loanapproval_id
        };
        dispatch(cibilData(cibil_data));
        dispatch(businessReportData(item.id))
    };

    let serialNo = PAGE_SIZE * slNo;

    const handleSearch = (e) => {
        setSearch(e.target.value)
        //handleChange(null, 1)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    return (
        <>
        {/* <div className={sharedClasses.mainDiv}> */}
            {/* <div className={sharedClasses.pageContent}> */}
            {/* <PageHeader
                header={TXT_LOAN_APPLICATION}
                subheader={`${TXT_HOME}/${TXT_LOAN_APPLICATION}`}
            /> */}
            {/* <div className={sharedClasses.lpsearchBar}> */}
                <Toolbar className={sharedClasses.tabToolbar}>
                <TextField
                    size="small"
                    className={sharedClasses.searchTextBar}
                    //value={search}
                    onChange={debouncedResults}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Name or RT/DT Code"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                    endAdornment: (
                        <IconButton>
                        <SearchOutlined
                            className={sharedClasses.searchIconColour}
                        />
                        </IconButton>
                    ),
                    }}
                />
                </Toolbar>
            {/* </div> */}
            <section className={sharedClasses.tabMainSection}>
                <TblContainer>
                <TblHead />
                {loading ? (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={10} align="center">
                        <ProgressSpinner />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                    {loanApplications.length !== 0 ? (
                        loanApplications.map((item, key) => {
                        serialNo++;
                        return (
                            <TableRow
                            key={item.id.toString()}
                            // sx={{
                            //   "&:last-child td, &:last-child th": { border: 0 },
                            // }}
                            >
                            <TableCell>{serialNo}</TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.userid}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.name}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.mobile}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.email}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.ShortName}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                                {item.amount}
                            </TableCell>
                            <TableCell className={sharedClasses.tableCell}>
                            {item.tenure}
                            </TableCell>
                            <TableCell
                                className={sharedClasses.tableCell}
                                onClick={() => handleShowDetails(item)}
                            >
                                <Link to="#">Show</Link>
                            </TableCell>
                            </TableRow>
                        );
                        })
                    ) : (
                        <TableRow>
                        <TableCell
                            colSpan={10}
                            align="center"
                            className={sharedClasses.tableCell}
                        >
                            No Data to Show
                        </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                )}
                </TblContainer>
                <div>
                <Snackbar {...snackBar} />
                </div>
                {showPageNtn ? <TblPagination /> : ""}
            </section>
            <CustomerData.Provider value={applicDetails}>
                {modalStatus === true ? (
                <Popups
                    data={applicDetails}
                    handleClick={handleShowDetails}
                    handleClose={handleClose}
                />
                ) : (
                " "
                )}
            </CustomerData.Provider>
            {/* </div> */}
        {/* </div> */}
        {
            appn_appr_rej_loader ? <LoadingModal open={appn_appr_rej_loader} /> : ''
        }
        </>
    );
};
export { CustomerData };
export default LoanApplication;
